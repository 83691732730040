@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body{ font-family: 'Montserrat', sans-serif; }
.App {
  text-align: center;
  background-color: black;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin)
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer base {
  :root {
    --gradient: linear-gradient(135deg, #A1FF8B 0%, #34D399 96.83%);
    --secondary-gradient: linear-gradient(135deg, #664cf8 0%, #34c6d3 96.83%);
    --oneSat-gradient: linear-gradient(135deg, #F0BB00 0%, #c39902 96.83%);
    --ice-gradient: linear-gradient(135deg, #4cf5f8 0%, #6b06d1 96.83%);

    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 0%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
 
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
 
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
 
    --primary: 60 9.1% 97.8%;
    --primary-foreground: 24 9.8% 10%;
 
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
 
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
 
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
 
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}